import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { ConditionsPanels } from '../components/Conditions-Panels';
import { StaticImage } from 'gatsby-plugin-image';
const breadCrumbNames = ['Services', 'Conditions'];
const breadCrumbUrls = ['/our-services/', '/conditions/'];
import { Seo } from '../components/seo';

const Conditions: Page = () => {
    return (
        <>
            <Seo
                title="Innovative Medicine Treatable Medical Illness with Therapies"
                description="We provide medical care for a number of different medical illnesses that we treat with the help of therapies."
            />
            <div className="small-container">
                <Hero
                    title="Conditions"
                    breadCrumbUrls={breadCrumbUrls}
                    breadCrumbNames={breadCrumbNames}
                >
                    {' '}
                    <StaticImage
                        className=""
                        src="../images/conditions-1.jpeg"
                        placeholder="none"
                        quality={100}
                        alt="man stressed out in office"
                    />
                </Hero>
                <p style={{ fontWeight: '700' }}>
                    Below are many other conditions treatable with our therapies:
                </p>
                <ConditionsPanels />
            </div>
        </>
    );
};
Conditions.Layout = DefaultLayout;

export default Conditions;
