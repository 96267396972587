import { SquarePanelsConditions } from './Square-Panels-Conditions';
import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
// import { colors, device } from './layout/GlobalStyles';

const StyledConditionsPanels = styled.div``;

export const ConditionsPanels: React.FC = () => {
    const data = useStaticQuery(graphql`
        query Conditions {
            allMdx(
                filter: { frontmatter: { slug: { regex: "/conditions/" } } }
                sort: { fields: frontmatter___title }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            slug
                            link
                        }
                    }
                }
            }
        }
    `);
    return (
        <StyledConditionsPanels className="container">
            <SquarePanelsConditions conditions={data.allMdx.edges}></SquarePanelsConditions>
        </StyledConditionsPanels>
    );
};
